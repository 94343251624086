<template>
    <div>
        <div class="uk-padding-small">
            <vue-good-table
                ref="vueTable"
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                :isLoading.sync="isLoading"
                :pagination-options="pagination"
                :totalRows="remoteData.totalRecords"
                :rows="remoteData.rows"
                :columns="columns"
                :sort-options="{ enabled: false }"
                :select-options="{
                    enabled: !!$store.getters['login/isAdminFunc'],
                    selectionText: 'Intervention sélectionnées',
                    clearSelectionText: 'effacer la sélection',
                }"
                styleClass="vgt-table striped bordered condensed"
            >
                <template slot="loadingContent">
                    <span class="vgt-loading__content">Téléchargement...</span>
                </template>
                <div slot="selected-row-actions">
                    <p v-if="processInProgress">{{ messageInprogress }}</p>
                    <button class="btn btn-primary dpmargin" :disabled="processInProgress" @click="reImportManyOs">
                        Réimport
                    </button>
                    <button class="btn btn-primary dpmargin" :disabled="processInProgress" @click="reExportManyOs">
                        Ré-export
                    </button>
                </div>
                <template v-slot:table-actions class="p-2 pt-0 pb-0 d-md-flex flex-row">
                    <SimpleAccordion>
                        <template v-slot:accordionContent>
                            <div class="d-flex flex-wrap">
                                <div class="d-flex align-items-center dpmargin">
                                    <button
                                        v-if="dateRange.startDate"
                                        @click="clearDateRange"
                                        class="btn dpmargin p-0 btn-filter"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="32"
                                            height="32"
                                            viewBox="0 0 24 24"
                                        >
                                            <title>Effacer filtre date</title>
                                            <path
                                                fill="#ffc107"
                                                d="m9.31 17l2.44-2.44L14.19 17l1.06-1.06l-2.44-2.44l2.44-2.44L14.19 10l-2.44 2.44L9.31 10l-1.06 1.06l2.44 2.44l-2.44 2.44L9.31 17zM19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11z"
                                            ></path>
                                        </svg>
                                    </button>
                                </div>
                                <div class="dpmargin boxDate">
                                    <DateRangePicker
                                        opens="center"
                                        :locale-data="{
                                            firstDay: 1,
                                            format: 'dd-mm-yyyy',
                                            applyLabel: 'Valider',
                                            cancelLabel: 'Annuler',
                                            daysOfWeek: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
                                            monthNames: [
                                                'Janvier',
                                                'Février',
                                                'Mars',
                                                'Avril',
                                                'Mai',
                                                'Juin',
                                                'Juillet',
                                                'Août',
                                                'Septembre',
                                                'Octobre',
                                                'Novembre',
                                                'Décembre',
                                            ],
                                        }"
                                        class="date-picker-full-height"
                                        v-model="dateRange"
                                        @update="
                                            (e) => {
                                                dateRange = e
                                            }
                                        "
                                    />
                                </div>
                                <div class="dpmargin d-flex align-items-center">
                                    <StatusSelect
                                        :selectedStatuses="selectedStatuses"
                                        @change="
                                            (e) => {
                                                selectedStatuses = e
                                            }
                                        "
                                    />
                                </div>
                                <!-- filter by regions/delta agency/ sub agency -->
                                <RegionsFilter v-if="isSuez" />

                                <!-- filter by agencies ST -->
                                <AgenciesFilter v-if="isWorkerAdmin" />

                                <div class="d-flex align-items-center dpmargin">
                                    <button @click="recordState" class="btn dpmargin p-0 btn-filter">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="32"
                                            height="32"
                                            viewBox="0 0 24 24"
                                            style=""
                                        >
                                            <title>Mémoriser filtre</title>
                                            <path
                                                fill="#00f"
                                                d="M6.75 3h-1A2.75 2.75 0 0 0 3 5.75v12.5A2.75 2.75 0 0 0 5.75 21H6v-6a2.25 2.25 0 0 1 2.25-2.25h7.5A2.25 2.25 0 0 1 18 15v6h.25A2.75 2.75 0 0 0 21 18.25V8.286a3.25 3.25 0 0 0-.952-2.299l-2.035-2.035A3.25 3.25 0 0 0 15.75 3v4.5a2.25 2.25 0 0 1-2.25 2.25H9A2.25 2.25 0 0 1 6.75 7.5V3Zm7.5 0v4.5a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75V3h6Zm2.25 18v-6a.75.75 0 0 0-.75-.75h-7.5a.75.75 0 0 0-.75.75v6h9Z"
                                            ></path>
                                        </svg>
                                    </button>
                                    <button @click="resetStateToDefault" class="btn dpmargin p-0 ms-1 btn-filter">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="32"
                                            height="32"
                                            viewBox="0 0 32 32"
                                        >
                                            <title>Réinitialiser filtre</title>
                                            <path
                                                fill="red"
                                                d="M22.5 9a7.452 7.452 0 0 0-6.5 3.792V8h-2v8h8v-2h-4.383a5.494 5.494 0 1 1 4.883 8H22v2h.5a7.5 7.5 0 0 0 0-15Z"
                                            ></path>
                                            <path
                                                fill="red"
                                                d="M26 6H4v3.171l7.414 7.414l.586.586V26h4v-2h2v2a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2v-8l-7.414-7.415A2 2 0 0 1 2 9.171V6a2 2 0 0 1 2-2h22Z"
                                            ></path>
                                        </svg>
                                    </button>
                                    <button
                                        v-if="
                                            ($store.getters['login/isWorkerAdmin'] ||
                                                $store.getters['login/isSuez'] ||
                                                $store.getters['login/isAdminFunc']) &&
                                            !shouldBeHidden('md')
                                        "
                                        @click="exportExcel"
                                        class="btn btn-success dpmargin"
                                    >
                                        Excel
                                    </button>
                                </div>
                            </div>
                        </template>
                    </SimpleAccordion>
                </template>
                <template v-slot:table-row="props">
                    <div v-if="props.column.field === 'actions'" class="uk-text-center">
                        <div v-if="$store.getters['login/isAdminFunc']">
                            <button
                                @click="reImportOfQualifyingData(props.row.id)"
                                class="btn btn-primary dpmargin import"
                                :disabled="reImportIsInProgress.includes(props.row.id)"
                            >
                                {{ !reImportIsInProgress.includes(props.row.id) ? 'Réimport' : '' }}
                                <div
                                    class="ms-2"
                                    uk-spinner="ratio:0.5"
                                    v-if="reImportIsInProgress.includes(props.row.id)"
                                ></div>
                            </button>
                            <button
                                @click="reExportOfQualifyingData(props.row.id)"
                                class="btn btn-primary dpmargin import"
                                :disabled="reExportIsInProgress.includes(props.row.id)"
                            >
                                {{ !reExportIsInProgress.includes(props.row.id) ? 'Ré-export' : '' }}
                                <div
                                    class="ms-2"
                                    uk-spinner="ratio:0.5"
                                    v-if="reExportIsInProgress.includes(props.row.id)"
                                ></div>
                            </button>
                        </div>
                    </div>
                    <div v-else-if="props.column.field === 'osNumero'">
                        <a
                            v-if="isScreenReduced"
                            href="#"
                            @click.prevent="
                                $router.push({ name: 'mission_order_view', params: { missionOrderId: props.row.id } })
                            "
                            v-html="replaceUnderscores(props.row.osNumero)"
                        ></a>
                        <a
                            v-else
                            href="#"
                            @click.prevent="
                                $router.push({ name: 'mission_order_view', params: { missionOrderId: props.row.id } })
                            "
                        >
                            {{ props.row.osNumero }}
                        </a>
                    </div>
                    <div v-else-if="props.column.field === 'g2_inter_code_wording'">
                        <div class="formatted-text mb-3">
                            <a
                                href="#"
                                @click.prevent="
                                    $router.push({
                                        name: 'mission_order_view',
                                        params: { missionOrderId: props.row.id },
                                    })
                                "
                            >
                                {{ props.row.g2_inter_code_wording }}
                            </a>
                        </div>
                        <div v-if="isScreenReduced" style="position: relative">
                            <div
                                v-if="activeTooltipG2InterCodeRow === props.row"
                                class="custom-tooltip g2-inter-code-position"
                            >
                                {{ props.row.g2_inter_code_wording }}
                            </div>
                            <button
                                class="btn btn-primary uk-padding-small uk-width uk-margin-small-top uk-margin-small-bottom"
                                @click="toggleCustomTooltipG2InterCode(props.row)"
                            >
                                <i class="fas fa-info-circle fa-lg uk-padding-small"></i>
                            </button>
                        </div>
                    </div>
                    <div v-else-if="props.column.field === 'progression'" class="uk-text-center">
                        <div v-if="props.row.status" class="progress-bar-width">
                            <div class="progress" v-if="props.row.status.value === $Status.toDo">
                                <div
                                    class="progress-bar"
                                    role="progressbar"
                                    style="width: 33%"
                                    :style="{ backgroundColor: getColorForStatus($Status.toDo) }"
                                    aria-valuenow="33"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            <div class="progress" v-else-if="props.row.status.value === $Status.canceled">
                                <div
                                    class="barred"
                                    role="progressbar"
                                    style="width: 100%"
                                    :style="{ backgroundColor: getColorForStatus($Status.canceled) }"
                                    aria-valuenow="100"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            <div class="progress" v-else>
                                <div
                                    class="progress-bar"
                                    role="progressbar"
                                    style="width: 33%"
                                    :style="{ backgroundColor: getColorForStatus($Status.toDo) }"
                                    aria-valuenow="33"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                                <div
                                    class="progress-bar"
                                    role="progressbar"
                                    style="width: 33%"
                                    :style="{ backgroundColor: getColorForStatus($Status.inProgress) }"
                                    aria-valuenow="33"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                                <div
                                    v-if="props.row.status.value !== $Status.inProgress"
                                    class="progress-bar"
                                    role="progressbar"
                                    style="width: 33%"
                                    :style="{ backgroundColor: getColorForStatus(props.row.status.value) }"
                                    aria-valuenow="33"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="props.column.field === 'status'">
                        <span
                            class="uk-label uk-border-rounded"
                            :style="{
                                backgroundColor: getColorForStatus(props.row.status.value),
                            }"
                            v-if="props.row.status"
                        >
                            {{ props.row.status.value }}
                        </span>
                    </div>
                    <div v-else-if="props.column.field === 'agency'">
                        <div>
                            {{ props.row.agency.name }}
                        </div>
                    </div>
                    <div v-else-if="props.column.field === 'case'">
                        <div v-if="osForSignatureRequired.includes(props.row.status.value)">
                            <input
                                class="uk-checkbox"
                                :disabled="remoteData.rows[props.index].status.value === 'Terminé'"
                                type="checkbox"
                                v-model="remoteData.rows[props.index].is_signature_required"
                                @change="
                                    updateIsSignatureRequired(
                                        props.row.id,
                                        remoteData.rows[props.index].is_signature_required,
                                    )
                                "
                            />
                        </div>
                    </div>
                    <div v-else-if="props.column.field === 'expectedStartDate'">
                        {{ $util.formatDate(props.row.expectedStartDate, 'DD/MM/YYYY', false) }}
                    </div>
                    <div v-else-if="props.column.field === 'property.address.formattedAddress'">
                        <div class="m-2 formatted-text">
                            {{ props.formattedRow[props.column.field] }}
                        </div>
                        <div v-if="isScreenReduced" style="position: relative">
                            <div
                                v-if="activeTooltipFormattedAddressRow === props.row"
                                class="custom-tooltip formatted-address-position"
                            >
                                {{ props.formattedRow[props.column.field] }}
                            </div>
                            <button
                                class="btn btn-primary uk-padding-small uk-width uk-margin-small-top uk-margin-small-bottom"
                                @click="toggleCustomTooltipFormattedAddress(props.row)"
                            >
                                <i class="fas fa-info-circle fa-lg uk-padding-small"></i>
                            </button>
                        </div>
                        <SimpleAccordion v-if="hiddenFields(props.formattedRow).length > 0">
                            <template v-slot:accordionTitle>Plus d'infos</template>
                            <template v-slot:accordionContent>
                                <div class="p-2">
                                    <div v-if="hiddenFields(props.formattedRow).includes('status')" class="mb-3">
                                        <p>Statut:</p>
                                        <div>
                                            <span
                                                class="badge small"
                                                :style="{
                                                    backgroundColor: getColorForStatus(props.row.status.value),
                                                }"
                                            >
                                                {{ props.formattedRow['status']['value'] }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </SimpleAccordion>
                    </div>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </div>
    </div>
</template>

<script>
import backendApi from '@/backend/api'
import store from '@/store'
import SimpleAccordion from '@/components/SimpleAccordion'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import * as Status from '@/Enum/Status'
import StatusSelect from '@/components/StatusSelect'
import RegionsFilter from '@/components/RegionsFilter'
import AgenciesFilter from '@/components/AgenciesFilter'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import StatusColors from '@/helpers/statusColors'

export default {
    name: 'MissionOrdersTable',
    components: { SimpleAccordion, DateRangePicker, StatusSelect, RegionsFilter, AgenciesFilter },
    data: () => ({
        messageInprogress: null,
        processInProgress: false,
        reImportIsInProgress: [],
        reExportIsInProgress: [],
        osForSignatureRequired: [Status.inProgress, Status.toDo, Status.suspended, Status.ended],
        columnFiltersLocalStorage: {},
        clearComulnFilter: '',
        isLoading: false,
        filterUser: {
            suez: {
                regions: [],
                agencies: [],
                subagencies: [],
            },
            workerAdmin: {
                agencies_st: [],
            },
        },
        pagination: {
            enabled: true,
            mode: 'records',
            perPage: 10,
            position: 'both',
            perPageDropdown: [10, 25, 50],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            jumpFirstOrLast: true,
            firstLabel: 'Début',
            lastLabel: 'Fin',
            nextLabel: 'Suiv.',
            prevLabel: 'Préc.',
            rowsPerPageLabel: 'Lignes par page ',
            ofLabel: 'de',
            pageLabel: 'page',
            allLabel: 'Tous',
        },
        remoteData: {
            rows: [],
            totalRecords: 0,
        },
        signatureColumn: {
            label: 'Signature O/N',
            field: 'case',
        },
        refDemandeG2: {
            label: 'Numéro G2',
            field: 'refDemandeG2',
        },
        osNomFournisseur: {
            label: 'Nom fournisseur',
            field: 'osNomFournisseur',
            filterOptions: {
                enabled: true,
            },
            sortable: true,
        },
        osLibelleRegion: {
            label: 'Region',
            field: 'osLibelleRegion',
            filterOptions: {
                enabled: true,
            },
            sortable: true,
        },
        osLibelleSousAgence: {
            label: 'Sous-Agence',
            field: 'osLibelleSousAgence',
            filterOptions: {
                enabled: true,
            },
            sortable: true,
        },
        osLibelleAgenceDelta: {
            label: 'Agence Delta',
            field: 'osLibelleAgenceDelta',
            filterOptions: {
                enabled: true,
            },
            sortable: true,
        },
        orderPriorityColumn: {
            label: 'Ordre/Priorité',
            field: 'orderPriority',
        },
        actionColumn: {
            label: 'Actions',
            field: 'actions',
            width: '1%',
        },
        serverParams: {
            _timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            extraFilter: {
                closed: false,
                status: [],
                dateRange: {
                    startDate: null,
                    endDate: null,
                },
            },
            columnFilters: {},
            sort: [],
            page: 1,
            perPage: 10,
        },
        intervalId: null,
        isScreenReduced: false,
        activeTooltipG2InterCodeRow: null,
        activeTooltipFormattedAddressRow: null,
    }),
    watch: {
        dateRange: {
            handler: function () {
                this.loadItems()
            },
            deep: true,
        },
        selectedStatuses: {
            handler: function () {
                this.loadItems()
            },
            deep: true,
        },
        suezOperatorSelected: {
            handler: function () {
                this.loadItems()
            },
            deep: true,
        },
        workerAdminSelected: {
            handler: function () {
                this.loadItems()
            },
            deep: true,
        },
    },
    computed: {
        ...mapState('missionOrderFilters', ['dateRange']),
        ...mapState('missionOrderFilters', {
            suezOperatorSelected: (state) => state.suezOperator.selected,
            workerAdminSelected: (state) => state.workerAdmin.selected,
        }),
        selectedStatuses: {
            get() {
                return this.$store.getters['missionOrderFilters/selectedStatuses']
            },
            set(value) {
                this.$store.commit('missionOrderFilters/setSelectedStatuses', value)
            },
        },
        dateRange: {
            get() {
                return this.$store.state.missionOrderFilters.dateRange
            },
            set(value) {
                this.$store.commit('missionOrderFilters/setDateRange', value)
            },
        },
        columns: function () {
            let column = [
                {
                    label: 'Numéro OS',
                    field: 'osNumero',
                    sortable: true,
                    hidden: this.shouldBeHidden('xs'),
                    filterOptions: {
                        enabled: true,
                    },
                },
                {
                    label: 'Agence ST',
                    field: 'agency',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                },
                {
                    label: 'Intervention',
                    field: 'g2_inter_code_wording',
                    hidden: this.shouldBeHidden('xs'),
                    filterOptions: {
                        enabled: true,
                        filterValue: this.clearComulnFilter
                            ? ''
                            : this.columnFiltersLocalStorage
                              ? this.columnFiltersLocalStorage['g2_inter_code']
                              : '',
                    },
                    sortable: true,
                },
                {
                    label: 'Date',
                    field: 'expectedStartDate',
                    hidden: this.shouldBeHidden('xs'),
                    sortable: true,
                },
                {
                    label: 'Adresse',
                    field: 'property.address.formattedAddress',
                    filterOptions: {
                        enabled: true,
                        filterValue: this.clearComulnFilter
                            ? ''
                            : this.columnFiltersLocalStorage
                              ? this.columnFiltersLocalStorage['property.address.formattedAddress']
                              : '',
                    },
                    sortable: true,
                    hidden: this.shouldBeHidden('xs'),
                },
                {
                    label: 'Statut',
                    field: 'status',
                    sortable: true,
                    hidden: this.shouldBeHidden('sm'),
                },
            ]

            if (this.$store.getters['login/isSuez']) {
                column.splice(2, 0, this.osNomFournisseur)
                column = [...column, this.signatureColumn]
            }

            if (this.$store.getters['login/isAdminFunc']) {
                column = [...column, this.refDemandeG2, this.actionColumn]
            }

            if (!this.$store.getters['login/isSuez'] && !this.$store.getters['login/isAdminFunc']) {
                // Column to hide : Nom de l'agence sous traitante for worker agent & manager
                const stAgencyToRemove = column.findIndex((item) => item.field === 'agency')
                column.splice(stAgencyToRemove, 1)
            }

            column = [...column, this.progressionBar]

            return column
        },
        progressionBar() {
            return {
                label: 'Progression',
                field: 'progression',
                hidden: this.shouldBeHidden('giga'),
            }
        },
        isSuez() {
            return this.$store.getters['login/isSuez']
        },
        isWorkerAdmin() {
            return this.$store.getters['login/isWorkerAdmin']
        },
        ...mapGetters('osRefreshTime', ['getOsRefreshTimeData']),
    },
    methods: {
        ...mapActions('missionOrderFilters', ['recordState']),
        ...mapMutations('missionOrderFilters', ['clearDateRange', 'resetStateToDefault']),
        updateIsSignatureRequired(Osid, is_signature_required) {
            const data = { is_signature_required: is_signature_required }
            this.$http
                .updateIsSignatureRequired(Osid, data)
                .then(() => {
                    this.$store.dispatch('notifications/addNotification', {
                        status: 'success',
                        message: 'Mis à jour effectuée avec succès',
                    })
                    this.loadItems()
                })
                .catch((e) => {
                    this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                })
        },
        exportExcel() {
            this.isLoading = true
            this.$http
                .exportExcel(this.prepareServerParams())
                .then((res) => {
                    const b64 =
                        'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.file
                    this.$util.downloadBase64(b64, `${Date.now()}.xlsx`)
                    // window.location.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + res.file
                    this.isLoading = false
                })
                .catch((e) => {
                    this.isLoading = false
                    this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                })
        },
        shouldBeHidden: function (at) {
            const bp = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl', 'big', 'giga']
            if (!bp.includes(at)) {
                return false
            }

            return !(bp.indexOf(at) <= bp.indexOf(this.$mq))
        },
        hiddenFields: function (formattedRow) {
            let hiddenFields = []
            this.columns.forEach((column) => {
                if (column.hidden) {
                    hiddenFields.push(column.field)
                    if (!formattedRow[column.field].length) {
                        hiddenFields.pop()
                    }
                }
            })

            return hiddenFields
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps)
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage })
            this.loadItems()
        },
        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage })
            this.loadItems()
        },
        onSortChange(params) {
            this.updateParams({ sort: params })
            this.loadItems()
        },
        onColumnFilter(params) {
            this.updateParams(params)
            this.loadItems()
        },
        prepareServerParams() {
            this.serverParams.extraFilter.status = Object.keys(this.selectedStatuses).filter(
                (key) => this.selectedStatuses[key],
            )
            this.serverParams.extraFilter.dateRange = this.dateRange
            const params = JSON.parse(JSON.stringify(this.serverParams))
            if (this.isSuez) {
                // params for exploitant suez
                params.extraFilter = {
                    ...params.extraFilter,
                    regions: this.suezOperatorSelected.regions.reduce((acc, item) => [...acc, item.code], []),
                    agencies: this.suezOperatorSelected.deltaAgencies.reduce((acc, item) => [...acc, item.code], []),
                    subagencies: this.suezOperatorSelected.subAgencies.reduce((acc, item) => [...acc, item.code], []),
                }
            }
            if (this.isWorkerAdmin) {
                // params for manager sous traitant
                params.extraFilter = {
                    ...params.extraFilter,
                    agencies_st: this.workerAdminSelected.providerAgencies.reduce((acc, item) => [...acc, item.id], []),
                }
            }

            return params
        },
        loadItems(isLoading = true) {
            this.isLoading = isLoading
            const params = this.prepareServerParams()

            backendApi
                .getMissionOrders(
                    store.state.login.user.token,
                    params,
                    this.$store.getters['login/isSuez'],
                    this.$store.getters['login/isAdminFunc'],
                )
                .then((data) => {
                    this.remoteData.totalRecords = data.totalRecords
                    this.remoteData.rows = data.rows
                    this.updateParams({
                        page: data.currentPage,
                        perPage: data.maxResult,
                    })
                })
                .catch((e) => {
                    this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        reImportOfQualifyingData(Osid) {
            this.reImportIsInProgress.push(Osid)
            this.$http
                .reImportOfQualifyingData(Osid)
                .then((res) => {
                    this.reImportIsInProgress.splice(this.reImportIsInProgress.indexOf(Osid), 1)
                    this.$store.dispatch('notifications/addNotification', { status: 'success', message: res.message })
                })
                .catch((e) => {
                    this.reImportIsInProgress.splice(this.reImportIsInProgress.indexOf(Osid), 1)
                    this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                })
        },
        reExportOfQualifyingData(Osid) {
            this.reExportIsInProgress.push(Osid)
            this.$http
                .reExportOfQualifyingData(Osid)
                .then((res) => {
                    this.reExportIsInProgress.splice(this.reExportIsInProgress.indexOf(Osid), 1)
                    this.$store.dispatch('notifications/addNotification', { status: 'success', message: res.message })
                })
                .catch((e) => {
                    this.reExportIsInProgress.splice(this.reExportIsInProgress.indexOf(Osid), 1)
                    this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                })
        },
        reImportManyOs() {
            this.processInProgress = true
            const data = {
                type: 'import',
                missionOrderIds: this.$refs.vueTable.selectedRows.map((s) => s.id),
            }
            this.$http
                .reImportExportOfQualifyingData(data)
                .then((res) => {
                    this.$store.dispatch('notifications/addNotification', { status: 'success', message: res.message })
                    this.messageInprogress = res.message
                })
                .catch((e) => {
                    this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                })
                .finally(() => {
                    this.processInProgress = false
                    this.$refs.vueTable.unselectAllInternal(true)
                })
        },
        reExportManyOs() {
            this.processInProgress = true
            const data = {
                type: 'export',
                missionOrderIds: this.$refs.vueTable.selectedRows.map((s) => s.id),
            }
            this.$http
                .reImportExportOfQualifyingData(data)
                .then((res) => {
                    this.$store.dispatch('notifications/addNotification', { status: 'success', message: res.message })
                    this.messageInprogress = res.message
                })
                .catch((e) => {
                    this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                })
                .finally(() => {
                    this.processInProgress = false
                    this.$refs.vueTable.unselectAllInternal(true)
                })
        },
        replaceUnderscores(text) {
            return text.replace(/_/g, '<br>')
        },
        checkScreenSize() {
            this.isScreenReduced = window.innerWidth <= 560
        },
        toggleCustomTooltipG2InterCode(index) {
            this.activeTooltipG2InterCodeRow = this.activeTooltipG2InterCodeRow === index ? null : index
        },
        toggleCustomTooltipFormattedAddress(index) {
            this.activeTooltipFormattedAddressRow = this.activeTooltipFormattedAddressRow === index ? null : index
        },
        getColorForStatus(status) {
            return StatusColors.getColorForStatus(status)
        },
    },
    mounted() {
        this.loadItems()
        this.intervalId = setInterval(() => this.loadItems(false), this.getOsRefreshTimeData * 60000) // Convert minutes to milliseconds
        this.checkScreenSize()
        window.addEventListener('resize', this.checkScreenSize)
    },
    beforeDestroy() {
        clearInterval(this.intervalId)
        window.removeEventListener('resize', this.checkScreenSize)
    },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.progress-bar-width {
    width: 140px;
}

.dpmargin {
    margin: 3px;
}

.progress {
    height: 39px;
}

.barred {
    position: relative;
    height: 5px;
    top: 46%;
}

.date-picker-full-height.vue-daterange-picker {
    height: 100%;
    display: flex !important;
    align-items: center;
}

.daterangepicker.show-ranges {
    min-width: auto !important;
}

.daterangepicker.show-calendar .ranges {
    display: none;
}

.boxDate {
    position: relative;
}

.clearDate {
    position: absolute;
    right: -5px;
    top: 0;
    cursor: pointer;
}

.import {
    width: 92px;
}

.accordion-button:focus {
    outline: none !important;
    box-shadow: none;
    border-color: transparent;
}

.btn-filter img {
    width: 35px;
    height: 35px;
}

@media only screen and (max-width: 560px) {
    .show-calendar {
        position: absolute !important;
        width: 100vw !important;
        height: auto !important;
        margin-top: 100% !important;
    }

    .calendars-container {
        justify-content: center !important;
        align-items: center !important;
        text-align: center !important;
    }

    .drp-buttons {
        text-align: center !important;
    }

    .formatted-text {
        width: 15ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media only screen and (min-width: 760px) {
    .daterangepicker.ltr.show-calendar.show-ranges.openscenter.linked {
        left: 100% !important;
    }
}

.fa-lg {
    font-size: 2.5em !important;
    cursor: pointer !important;
}

.custom-tooltip {
    position: absolute;
    width: 65vw;
    padding: 0.5rem;
    font-size: 1.2rem !important;
    background-color: #333;
    color: #fff;
    border-radius: 2px;
    right: 0;
    bottom: calc(100% + 0.5rem);
}

.g2-inter-code-position {
    left: 0;
}

.formatted-address-position {
    right: 0;
}
</style>
