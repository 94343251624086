<template>
    <simple-accordion>
        <template v-slot:accordionTitle> Filtres </template>
        <template v-slot:accordionContent>
            <div class="d-flex flex-wrap flex-row justify-content-end align-items-center p-3">
                <button v-if="dateRange.startDate" @click="clearDateRange" type="button" class="btn">
                    <img src="@/assets/calendar-close.svg" />
                </button>
                <DateRangePicker
                    class="me-2"
                    opens="right"
                    :ranges="false"
                    :time-picker="false"
                    single-date-picker="range"
                    :locale-data="dateTimePickerLocale"
                    v-model="dateRange"
                    @update="(e) => setDateRange(e)"
                />
                <StatusSelect :selectedStatuses="selectedStatuses" @change="setSelectedStatuses" />
                <RegionsFilter class="ms-2 me-2" v-if="isSuez" />
                <AgenciesFilter class="ms-2 me-2" v-if="isWorkerAdmin" />
                <button @click="() => recordState()" class="btn">
                    <img src="@/assets/record.svg" />
                </button>
                <button @click="() => resetStateToDefault()" class="btn dpmargin p-0 ms-1 btn-filter">
                    <img src="@/assets/reset-filters.svg" />
                </button>
            </div>
            <table class="table table-bordered table-borderless m-0 w-100">
                <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col" v-for="(value, index) in searchableFields" :key="`label_${index}`">
                            {{ value.label }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">Filtre</th>
                        <td class="p-0" v-for="(value, index) in searchableFields" :key="`filter_${index}`">
                            <input
                                type="text"
                                class="form-control border-0"
                                placeholder="entrez un texte"
                                :value="getFieldFilters(value.mapping)"
                                @change="(e) => setFieldFilters({ field: value.mapping, value: e.target.value })"
                                @keyup="(e) => setFieldFilters({ field: value.mapping, value: e.target.value })"
                            />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Tri</th>
                        <td colspan="6" class="p-0">
                            <select class="form-control border-0" v-model="sortBy">
                                <option :value="{ field: null, order: null }"></option>
                                <option
                                    v-for="(value, index) in sortableFields"
                                    :key="`sort_asc_${index}`"
                                    :value="{ field: value.mapping, order: 'asc' }"
                                >
                                    {{ value.label }} ASC
                                </option>
                                <option
                                    v-for="(value, index) in sortableFields"
                                    :key="`sort_desc_${index}`"
                                    :value="{ field: value.mapping, order: 'desc' }"
                                >
                                    {{ value.label }} DESC
                                </option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </template>
    </simple-accordion>
</template>

<script>
import SimpleAccordion from '@/components/SimpleAccordion.vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { dateTimePickerLocale } from '@/_config/date-time-picker-locale'
import StatusSelect from '@/components/StatusSelect.vue'
import RegionsFilter from '@/components/RegionsFilter.vue'
import AgenciesFilter from '@/components/AgenciesFilter.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { debounce } from 'lodash'

/**
 * @typedef {'suez' | 'worker' | 'workerAdmin' | 'adminFunc'} UserRole
 * @typedef {'all' | UserRole} AvailableFor
 * @typedef {{
 *     label: string,
 *     isSearchable: boolean,
 *     isSortable: boolean,
 *     mapping: string,
 *     availableFor: AvailableFor[]
 * }} Field
 */
export default {
    name: 'mission-order-filters',
    components: { AgenciesFilter, RegionsFilter, StatusSelect, SimpleAccordion, DateRangePicker },
    data: () => ({
        /**
         * @type {array<Field>}
         */
        fieldDefinitions: [
            {
                label: "Numéro d'os",
                isSearchable: true,
                isSortable: true,
                mapping: 'number',
                availableFor: ['all'],
            },
            {
                label: 'Agence ST',
                isSearchable: true,
                isSortable: true,
                mapping: 'providerAgency',
                availableFor: ['adminFunc', 'suez'],
            },
            {
                label: 'Nom Fournisseur',
                isSearchable: true,
                isSortable: true,
                mapping: 'providerName',
                availableFor: ['suez'],
            },
            {
                label: 'Intervention',
                isSearchable: true,
                isSortable: true,
                mapping: 'g2CodeWording',
                availableFor: ['all'],
            },
            {
                label: 'Adresse',
                isSearchable: true,
                isSortable: true,
                mapping: 'formattedAddress',
                availableFor: ['all'],
            },
            {
                label: 'Numéro G2',
                isSearchable: true,
                isSortable: true,
                mapping: 'g2Reference',
                availableFor: ['adminFunc'],
            },
            {
                label: 'Date de début',
                isSearchable: false,
                isSortable: true,
                mapping: 'expectedStartDate',
                availableFor: ['all'],
            },
            {
                label: 'Statut',
                isSearchable: false,
                isSortable: true,
                mapping: 'status',
                availableFor: ['all'],
            },
        ],
    }),
    computed: {
        dateTimePickerLocale() {
            return dateTimePickerLocale
        },
        userRole() {
            if (this.isAdminFunc) return 'adminFunc'
            if (this.isSuez) return 'suez'
            if (this.isWorkerAdmin) return 'workerAdmin'
            if (this.isWorker) return 'worker'
            return undefined
        },
        searchableFields() {
            return this.fieldDefinitions.filter(
                (field) => field.isSearchable && this.availableFor(field.availableFor, this.userRole),
            )
        },
        sortableFields() {
            return this.fieldDefinitions.filter(
                (field) => field.isSortable && this.availableFor(field.availableFor, this.userRole),
            )
        },
        selectedStatuses: {
            get() {
                return this._selectedStatuses
            },
            set() {},
        },
        dateRange: {
            get() {
                return this._dateRange
            },
            set() {},
        },
        sortBy: {
            get() {
                return this._sortBy
            },
            set(sortBy) {
                this.setSortBy(sortBy)
            },
        },
        ...mapState('missionOrderFilters', {
            _selectedStatuses: 'selectedStatuses',
            _dateRange: 'dateRange',
            _sortBy: 'sortBy',
            _fieldFilters: 'fieldFilters',
        }),
        ...mapGetters('login', ['isAdminFunc', 'isSuez', 'isWorkerAdmin', 'isWorker']),
        setFieldFilters() {
            return debounce(this._setFieldFilters, 500)
        },
    },
    methods: {
        getFieldFilters(field) {
            return this._fieldFilters[field]
        },
        /**
         * @param {AvailableFor[]} availableFor
         * @param {UserRole} userRole
         * @returns {boolean}
         */
        availableFor(availableFor, userRole) {
            if (availableFor.includes('all')) return true
            return availableFor.includes(userRole)
        },
        ...mapMutations('missionOrderFilters', [
            'clearDateRange',
            'resetStateToDefault',
            'setSelectedStatuses',
            'setDateRange',
            'setSortBy',
        ]),
        ...mapMutations('missionOrderFilters', {
            _setFieldFilters: 'setFieldFilters',
        }),
        ...mapActions('missionOrderFilters', ['recordState']),
    },
}
</script>
