<template>
    <div class="d-flex">
        <div class="progress flex-grow-1" :style="{ height: height }" v-if="status === statusEnum.toDo">
            <div
                class="progress-bar"
                role="progressbar"
                style="width: calc(100% / 3)"
                :style="{ backgroundColor: getColorForStatus(status) }"
                aria-valuenow="33"
                aria-valuemin="0"
                aria-valuemax="100"
            ></div>
        </div>
        <div class="progress flex-grow-1" :style="{ height: height }" v-else-if="status === statusEnum.canceled">
            <div
                class="barred"
                role="progressbar"
                style="width: 100%"
                :style="{ backgroundColor: getColorForStatus(status) }"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
            ></div>
        </div>
        <div class="progress flex-grow-1" :style="{ height: height }" v-else>
            <div
                class="progress-bar"
                role="progressbar"
                style="width: calc(100% / 3)"
                :style="{ backgroundColor: getColorForStatus(statusEnum.toDo) }"
                aria-valuenow="33"
                aria-valuemin="0"
                aria-valuemax="100"
            ></div>
            <div
                class="progress-bar"
                role="progressbar"
                style="width: calc(100% / 3)"
                :style="{ backgroundColor: getColorForStatus(statusEnum.inProgress) }"
                aria-valuenow="33"
                aria-valuemin="0"
                aria-valuemax="100"
            ></div>
            <div
                v-if="status !== statusEnum.inProgress"
                class="progress-bar"
                role="progressbar"
                style="width: calc(100% / 3)"
                :style="{ backgroundColor: getColorForStatus(status) }"
                aria-valuenow="33"
                aria-valuemin="0"
                aria-valuemax="100"
            ></div>
        </div>
    </div>
</template>

<script>
import { statusEnum } from '@/Enum/Status'
import StatusColors from '@/helpers/statusColors'

export default {
    name: 'progression',
    props: {
        status: {
            type: String,
            required: true,
        },
        height: {
            type: String,
            default: '2rem',
        },
    },
    computed: {
        statusEnum() {
            return statusEnum
        },
    },
    methods: {
        getColorForStatus(status) {
            return StatusColors.getColorForStatus(status)
        },
    },
}
</script>

<style scoped>
.barred {
    position: relative;
    height: 5px;
    top: 46%;
}
</style>
