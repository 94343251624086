<template>
    <div class="container-fluid p-3">
        <div class="d-flex flex-column">
            <div>
                <mission-order-filters />
            </div>
            <div class="align-self-stretch">
                <mission-order-card
                    v-for="missionOrder in missionOrders"
                    :key="`mo_card_${missionOrder.id}`"
                    :mission-order="OsHelper.osSummary(missionOrder)"
                />
                <div v-if="isLoading" class="container text-center mt-4 mb-4">
                    <div class="spinner-border fs-7" style="width: 3rem; height: 3rem" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div v-if="noMoreResult" class="alert alert-info fs-5 text-center mt-4 mb-4" role="alert">
                    Fin de la liste des ordres de mission
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MissionOrderFilters from '@/components/mission-order/mission-order-filters.vue'
import MissionOrderCard from '@/components/mission-order/mission-order-card.vue'
import { OsHelper } from '@/helpers/osHelper'
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
    name: 'mission-orders-card',
    computed: {
        ...mapState('missionOrdersList', ['missionOrders', 'isLoading', 'noMoreResult']),
        OsHelper() {
            return OsHelper
        },
    },
    methods: {
        ...mapMutations('missionOrdersList', ['clearMissionOrders']),
        ...mapActions('missionOrdersList', ['getMissionOrders']),
        isNearBottom() {
            const scrollTop = window.scrollY || document.documentElement.scrollTop
            const scrollHeight = document.documentElement.scrollHeight
            const clientHeight = document.documentElement.clientHeight

            // You can adjust the threshold value as needed
            const threshold = 150

            return scrollHeight - scrollTop <= clientHeight + threshold
        },
        loadNextMissionOrders() {
            if (this.isNearBottom() && !this.isLoading) {
                this.getMissionOrders()
            }
        },
    },
    components: { MissionOrderCard, MissionOrderFilters },
    mounted() {
        this.clearMissionOrders()
        setTimeout(() => {
            this.getMissionOrders()
            window.addEventListener('scroll', this.loadNextMissionOrders)
        }, 2500)
    },
    destroyed() {
        window.removeEventListener('scroll', this.loadNextMissionOrders)
    },
}
</script>
