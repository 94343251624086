<template>
    <div class="d-flex justify-content-center flex-wrap">
        <button
            class="m-1"
            :class="`btn ${isSelected(key) ? 'btn-success' : 'btn-secondary'}`"
            v-for="(value, key) in availableStatuses"
            :key="key"
            @click="() => handleClick(key)"
        >
            {{ value }}
        </button>
    </div>
</template>

<script>
import * as Status from '../Enum/Status'

export default {
    name: 'StatusSelect',
    props: {
        selectedStatuses: {
            type: Object,
            required: true,
        },
    },
    computed: {
        /**
         * @return Record<string, string>
         */
        availableStatuses() {
            const statuses = Object.assign({}, Status.statusSymbol)

            for (const key in statuses) {
                if (this.selectedStatuses[key] === undefined) {
                    delete statuses[key]
                }
            }

            return statuses
        },
    },
    methods: {
        isSelected(key) {
            return this.selectedStatuses[key]
        },
        handleClick(key) {
            this.selectedStatuses[key] = !this.selectedStatuses[key]

            this.$emit('change', this.selectedStatuses)
        },
    },
}
</script>
