<template>
    <div class="d-flex flex-wrap justify-content-end">
        <div class="m-2">
            <multiselect
                v-model="suezOperator.selected.regions"
                :options="regionsList"
                selectedLabel="Sélectionnez une région"
                selectLabel=""
                deselectLabel=""
                track-by="id"
                label="libelle"
                :multiple="true"
                placeholder="Régions"
                @input="(e) => setRegionsForSuezOperator(e)"
            >
                <template v-slot:no-options> Aucune région disponible </template>
            </multiselect>
        </div>
        <div v-if="deltaAgenciesList.length > 0" class="m-2">
            <div v-if="suezOperator.isLoadingData" class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <multiselect
                v-else
                v-model="suezOperator.selected.deltaAgencies"
                :options="deltaAgenciesList"
                selectedLabel="Sélectionnez un agence"
                selectLabel="Cliquez pour sélectionner"
                deselectLabel="Cliquez pour désélectionner"
                track-by="id"
                label="libelle"
                :multiple="true"
                placeholder="Agences"
                @input="(e) => setDeltaAgenciesForSuezOperator(e)"
            >
                <template v-slot:noOptions> Aucun agence disponible </template>
            </multiselect>
        </div>

        <div v-if="subAgenciesList.length > 0" class="m-2">
            <div v-if="suezOperator.isLoadingData" class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <multiselect
                v-else
                v-model="suezOperator.selected.subAgencies"
                :options="subAgenciesList"
                selectedLabel="Sélectionnez un sous agence"
                selectLabel="Cliquez pour sélectionner"
                deselectLabel="Cliquez pour désélectionner"
                track-by="id"
                label="libelle"
                :multiple="true"
                placeholder="Sous agences"
                @input="(e) => setSubAgenciesForSuezOperator(e)"
            >
                <template v-slot:no-options> Aucun sous agence disponible </template>
            </multiselect>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
    name: 'RegionsFilter',
    computed: {
        ...mapState('missionOrderFilters', ['suezOperator']),
        ...mapGetters('missionOrderFilters', ['regionsList', 'deltaAgenciesList', 'subAgenciesList']),
    },
    methods: {
        ...mapActions('missionOrderFilters', [
            'setRegionsForSuezOperator',
            'setDeltaAgenciesForSuezOperator',
            'setSubAgenciesForSuezOperator',
        ]),
    },
    mounted() {
        this.$store.dispatch('missionOrderFilters/loadDataForSuezOperator')
    },
}
</script>
