<template>
    <span class="badge text-lg" :style="{ backgroundColor: getColorForStatus(status) }">
        {{ status }}
    </span>
</template>

<script>
import StatusColors from '@/helpers/statusColors'

export default {
    name: 'Badge',
    props: {
        status: {
            type: String,
            required: true,
        },
    },
    methods: {
        getColorForStatus(status) {
            return StatusColors.getColorForStatus(status)
        },
    },
}
</script>
