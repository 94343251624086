export const dateTimePickerLocale = {
    firstDay: 1,
    format: 'dd-mm-yyyy',
    applyLabel: 'Valider',
    cancelLabel: 'Annuler',
    daysOfWeek: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
    monthNames: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
    ],
}
