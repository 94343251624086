<template>
    <div class="d-flex align-items-center dpmargin">
        <multiselect
            v-model="workerAdmin.selected.providerAgencies"
            :options="providerAgenciesList"
            selectedLabel="Sélectionnez un agence"
            selectLabel=""
            deselectLabel=""
            track-by="id"
            label="name"
            :multiple="true"
            placeholder="Agences Forunisseur"
            @input="(e) => setSelectedProviderAgencies(e)"
        >
            <template v-slot:no-options> Aucun agence disponible </template>
        </multiselect>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
    name: 'AgenciesFilter',
    computed: {
        ...mapState('missionOrderFilters', ['workerAdmin']),
        ...mapGetters('missionOrderFilters', ['providerAgenciesList']),
    },
    methods: {
        ...mapMutations('missionOrderFilters', ['setSelectedProviderAgencies']),
    },
    mounted() {
        this.$store.dispatch('missionOrderFilters/loadDataForWorkerAdmin')
    },
}
</script>
