export class OsHelper {
    /**
     * @param {{
     *      "id": Number,
     *      "property": {
     *          "address": {
     *              "formattedAddress": String,
     *              "lat": Number,
     *              "lng": Number
     *          }
     *      },
     *      "status": {
     *          "value": String,
     *      },
     *      "g2_inter_code": String,
     *      "expectedStartDate": String,
     *      "expectedEndDate": String,
     *      "g2_inter_code_wording": String,
     *      "osNumeroG2": String,
     *      "is_signature_required": Boolean,
     *      "osLibelleCodeStandard": String,
     *      "osNumero": String,
     *      "orderPriority": Number,
     *      "osCodeStandard": String,
     *      "agency": {
     *          "name": String
     *      },
     *      "osCodeRegion": String,
     *      "osLibelleAgenceDelta": String,
     *      "osLibelleSousAgence": String,
     *      "osNomFournisseur": String,
     *      "osCodeAgenceFournisseur": String,
     *      "osCodeFournisseur": String,
     *      "osDateHeureFinPrevue": String,
     *      "osLibelleRegion": String,
     *      "osDateHeureDebutPrevue": String,
     *      "refDemandeG2": String,
     * }} os
     * return {{
     *      id: number,
     *      status: string,
     *      number: string,
     *      providerAgency: string,
     *      providerName: string,
     *      g2CodeWording: string,
     *      expectedStartDate: string,
     *      address: {
     *          formattedAddress: string,
     *          lat: number,
     *          lng: number,
     *      }
     *      g2Reference: string,
     *      isSignatureRequired: string,
     * }}
     */
    static osSummary(os) {
        return {
            id: os.id,
            status: os.status.value,
            number: os.osNumero,
            providerAgency: os.agency.name,
            providerName: os.osNomFournisseur,
            g2CodeWording: os.g2_inter_code_wording,
            expectedStartDate: os.expectedStartDate,
            address: {
                formattedAddress: os.property.address.formattedAddress,
                lat: os.property.address.lat,
                lng: os.property.address.lng,
            },
            g2Reference: os.refDemandeG2,
            isSignatureRequired: os.is_signature_required,
        }
    }
}
