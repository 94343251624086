<template>
    <div class="card bg-light mt-4 mb-4">
        <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
                <div class="fs-5">Statut: <Badge class="ms-2" :status="missionOrder.status" /></div>
                <div class="fs-5 d-flex align-items-center">
                    <div>Progression:</div>
                    <div><Progression class="ms-2" style="width: 7rem" :status="missionOrder.status" /></div>
                </div>
            </div>
            <div class="fs-5 mt-2 mb-2">Numéro OS : {{ missionOrder.number }}</div>
            <div v-if="isAdminFunc || isSuez" class="fs-5 mt-2 mb-2">Agence ST : {{ missionOrder.providerAgency }}</div>
            <div v-if="isSuez" class="fs-5 mt-2 mb-2">Nom fournisseur : {{ missionOrder.providerName }}</div>
            <div class="fs-5 mt-2 mb-2">Intervention : {{ missionOrder.g2CodeWording }}</div>
            <div class="fs-5 mt-2 mb-2">
                Date : {{ $util.formatDate(missionOrder.expectedStartDate, 'DD/MM/YYYY', false) }}
            </div>
            <div class="fs-5 mt-2 mb-2">Adresse : {{ missionOrder.address.formattedAddress }}</div>
            <div v-if="isAdminFunc" class="fs-5 mt-2 mb-2">Numéro G2 : {{ missionOrder.g2Reference }}</div>
            <div v-if="isSuez" class="fs-5 mt-2 mb-2">
                Signature O/N :
                <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="missionOrder.isSignatureRequired"
                    @change="updateIsSignatureRequired(missionOrder.id, missionOrder.isSignatureRequired)"
                />
            </div>
            <div class="row pt-2">
                <div v-if="!isAdminFunc" class="col">
                    <a
                        :href="navUrl(missionOrder.address.formattedAddress)"
                        class="btn btn-primary"
                        target="_blank"
                        role="button"
                    >
                        Naviguer
                    </a>
                </div>
                <div class="col-auto">
                    <button
                        class="btn btn-primary"
                        @click.prevent="
                            $router.push({
                                name: 'mission_order_view',
                                params: { missionOrderId: missionOrder.id },
                            })
                        "
                    >
                        Accéder à l'OS
                    </button>
                </div>
                <div v-if="isAdminFunc" class="col-auto">
                    <button
                        @click="reImportOfQualifyingData(missionOrder.id)"
                        class="btn btn-primary"
                        :disabled="reImportIsInProgress.includes(missionOrder.id)"
                    >
                        {{ !reImportIsInProgress.includes(missionOrder.id) ? 'Réimport' : '' }}
                        <div
                            class="ms-2"
                            uk-spinner="ratio:0.5"
                            v-if="reImportIsInProgress.includes(missionOrder.id)"
                        ></div>
                    </button>
                </div>
                <div v-if="isAdminFunc" class="col-auto">
                    <button
                        @click="reExportOfQualifyingData(missionOrder.id)"
                        class="btn btn-primary"
                        :disabled="reExportIsInProgress.includes(missionOrder.id)"
                    >
                        {{ !reExportIsInProgress.includes(missionOrder.id) ? 'Ré-export' : '' }}
                        <div
                            class="ms-2"
                            uk-spinner="ratio:0.5"
                            v-if="reExportIsInProgress.includes(missionOrder.id)"
                        ></div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import backendApi from '@/backend/api'
import { mapGetters } from 'vuex'
import StatusColors from '@/helpers/statusColors'
import Badge from '@/components/mission-order/badge.vue'
import Progression from '@/components/mission-order/progression.vue'

/**
 * @typedef {{
 *      id: number,
 *      status: string,
 *      number: string,
 *      providerAgency: string,
 *      providerName: string,
 *      g2CodeWording: string,
 *      expectedStartDate: string,
 *      address: {
 *          formattedAddress: string,
 *          lat: number,
 *          lng: number,
 *          }
 *      g2Reference: string,
 *      isSignatureRequired: string,
 * }} MissionOrder
 */
export default {
    name: 'MissionOrderCard',
    components: { Progression, Badge },
    props: {
        missionOrder: {
            /** @type {PropType<MissionOrder>} */
            type: Object,
            required: true,
        },
    },
    data: () => ({
        reImportIsInProgress: [],
        reExportIsInProgress: [],
    }),
    computed: {
        ...mapGetters('login', ['isAdminFunc', 'isSuez', 'isWorkerAdmin', 'isWorker']),
    },
    methods: {
        navUrl(formattedAddress) {
            return 'https://www.google.com/maps/dir//' + formattedAddress
        },
        reImportOfQualifyingData(missionOrderId) {
            this.reImportIsInProgress.push(missionOrderId)
            backendApi
                .reImportOfQualifyingData(missionOrderId)
                .then((res) => {
                    this.reImportIsInProgress.splice(this.reImportIsInProgress.indexOf(missionOrderId), 1)
                    this.$store.dispatch('notifications/addNotification', { status: 'success', message: res.message })
                })
                .catch((e) => {
                    this.reImportIsInProgress.splice(this.reImportIsInProgress.indexOf(missionOrderId), 1)
                    this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                })
        },
        reExportOfQualifyingData(missionOrderId) {
            this.reExportIsInProgress.push(missionOrderId)
            backendApi
                .reExportOfQualifyingData(missionOrderId)
                .then((res) => {
                    this.reExportIsInProgress.splice(this.reExportIsInProgress.indexOf(missionOrderId), 1)
                    this.$store.dispatch('notifications/addNotification', { status: 'success', message: res.message })
                })
                .catch((e) => {
                    this.reExportIsInProgress.splice(this.reExportIsInProgress.indexOf(missionOrderId), 1)
                    this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                })
        },
        updateIsSignatureRequired(Osid, is_signature_required) {
            const data = { is_signature_required: is_signature_required }
            this.$http
                .updateIsSignatureRequired(Osid, data)
                .then(() => {
                    this.$store.dispatch('notifications/addNotification', {
                        status: 'success',
                        message: 'Mis à jour effectuée avec succès',
                    })
                })
                .catch((e) => {
                    this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                })
        },
        getColorForStatus(status) {
            return StatusColors.getColorForStatus(status)
        },
    },
}
</script>
