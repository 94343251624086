<template>
    <div>
        <MqLayout mq="md+">
            <MissionOrdersTable />
        </MqLayout>
        <MqLayout :mq="['xs', 'sm']">
            <MissionOrdersCard />
        </MqLayout>
    </div>
</template>

<script>
import MissionOrdersTable from '@/components/mission-order/MissionOrdersTable.vue'
import MissionOrdersCard from '@/components/mission-order/MissionOrdersCard.vue'
import MqLayout from 'vue-mq/src/component'

export default {
    name: 'MissionOrders',
    components: { MissionOrdersCard, MissionOrdersTable, MqLayout },
}
</script>
